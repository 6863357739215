"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListFileOperations = useListFileOperations;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var PAGE_SIZE = 10;
function useListFileOperations(skip) {
    var _a, _b;
    var _c = (0, client_1.useQuery)(api_1.Queries.fileOperation.listFileOperations, { variables: { input: { limit: PAGE_SIZE, offset: 0 } }, skip: skip }), data = _c.data, loading = _c.loading, error = _c.error, fetchMore = _c.fetchMore;
    var loadMore = (0, react_1.useCallback)(function () {
        var _a, _b;
        if (!(0, lodash_1.isNil)((_a = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _a === void 0 ? void 0 : _a.items) && ((_b = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _b === void 0 ? void 0 : _b.hasMore)) {
            var offset = data.listFileOperations.items.length;
            fetchMore({
                variables: {
                    input: { limit: PAGE_SIZE, offset: offset },
                },
            });
        }
    }, [data === null || data === void 0 ? void 0 : data.listFileOperations, fetchMore]);
    return (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d;
        return ({
            fileOperations: (_b = (_a = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [],
            hasMore: (_d = (_c = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _c === void 0 ? void 0 : _c.hasMore) !== null && _d !== void 0 ? _d : false,
            loadMore: loadMore,
            loading: loading,
            error: error,
        });
    }, [(_a = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _a === void 0 ? void 0 : _a.items, loadMore, loading, error, (_b = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _b === void 0 ? void 0 : _b.hasMore]);
}
